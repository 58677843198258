<template>
    <div class="policy">
        <p><strong>更新日期：2024年10月21日</strong></p>
        <p><strong>云服务协议</strong></p>
        <p><strong>1. 一般规定</strong></p>
        <p>1.1.
            睿云联云服务及其相关的技术和功能（以下简称为“本服务”）由睿云联(厦门)网络通讯技术有限公司，统一社会信用代码（纳税人识别号）9135020005116606XK，注册地为中国厦门软件园二期观日路56号10楼(以下简称“睿云联”)，邮编361009运营，
            本用户协议系睿云联与使用及访问本服务的用户（以下简称为“您”或“用户”）所订立的有效合约。“服务”是指数字内容,而数字内容指数据生产和提供的数字形式,如计算机程序、应用程序、游戏、音乐、视频或文本;本协议规定的性能主体是以电子形式发布的数字内容，客户不会在数据载体上接收货物，而是以电子形式(许可提供)接收货物，作为激活许可密钥或下载安装文件的链接;或其他服务可用的网页端业务。
        </p>
        <p>1.2.
            本合同条款为本合同不可分割的组成部分，以中文书写。当睿云联或客户使用的第三方系统/应用软件/服务运营数字内容由于市场情况变化/第三方交易许可条件变化而发生变化时，睿云联有权修改条款。同时，睿云联有权在会造成权利和义务的严重失衡的情况发生时，如经济或金融状况发生变化、货币变化(例如采用欧元)、通货膨胀等，修改条款。
        </p>
        <p>1.3. 睿云联应在条款更改生效日期前一个月内通过客户在网站上提供的电子邮件地址将条款更改发送给客户。</p>
        <p>1.4. 客户如果不同意变更，有权拒绝变更条款，并有权自被通知变更之日起一个月内终止本合同。终止通知必须以书面形式发出，通知期限为一个月，从书面通知送达睿云联注册办事处的地址开始计算。</p>
        <p><strong>2. 合同前资料</strong></p>
        <p>睿云联通知:</p>
        <p>a) 睿云联提供的服务的主体可以重复履行，而缔约双方受约束的最短期限为一个月;</p>
        <p>b) 睿云联提供法律规定的所有服务，但与服务提供方式有关的服务交付费用不适用;</p>
        <p><strong>3. 用户帐号</strong></p>
        <p>3.1. 客户注册用户账号后有权使用自己的用户界面，客户可将其用于服务(以下简称“用户帐号”)。</p>
        <p>3.2. 客户在本网站注册及订购服务时，均须提供正确、真实的资料。如用户账号内的资料有任何更改，客户亦须更新资料。睿云联默认客户在订购服务时提供的数据是正确的。</p>
        <p>3.3. 访问用户账号受到用户名和密码的保护。客户应妥善保管的帐号用户名与密码，不得与任何人分享此类信息。</p>
        <p>3.4. 客户无权允许第三方访问其用户帐户。客户如果怀疑密码被滥用或泄露给第三方，应立即通知睿云联。如果有正当理由担心用户帐号的访问数据被滥用或可能被滥用，睿云联有权冻结用户帐号。</p>
        <p><strong>4. 订立合同</strong></p>
        <p>4.1. 客户确认，服务内容充实，睿云联无需就这些服务签订合同。</p>
        <p>4.2. 客户购买硬件的同时订购服务，并收到用户账号。</p>
        <p>4.3. 睿云联默认订单所提供的数据是正确的。订单发出后，睿云联立即向客户确认其接受(包括订单摘要)，发送至客户在用户账户中给出的电子邮件地址(以下简称“客户电子邮件”)。</p>
        <p>4.4. 睿云联与客户之间的合同为订单(验收)交付，睿云联将订单(验收)通过电子邮件发送至客户的电子邮件。</p>
        <p><strong>5. 交货条件</strong></p>
        <p>5.1. 睿云联需在2个工作日内向客户提供服务。睿云联保留交付部分服务的权利。</p>
        <p>5.2. 服务为电子形式，作为激活许可密钥或下载数字内容的链接，发送至客户的电子邮箱。客户可以将数字内容下载到客户的数据存储中，或通过激活数字内容的软件密钥安装数据内容到设备上。</p>
        <p>5.3. 考虑到服务的性质，本服务不收取运费。</p>
        <p>5.4. 客户在收到服务后须检查其功能性和可用性。如果出现任何问题，客户须立即通知睿云联。</p>
        <p><strong>6. 关于云服务的信息</strong></p>
        <p>6.1. 睿云联将为每位用户提供有关数字内容功能的信息，包括技术保护措施，与硬件和软件兼容性的数据。</p>
        <p>客户须在订购以电子形式提供的服务之前核实，并在订购服务后确认:</p>
        <p>a)有合适的网络连接，可下载数字内容(安装文件);</p>
        <p>b)数字内容(安装文件)只能用于一台设备;客户端需要自行备份数字内容(安装文件)。睿云联无需提供多个链接或激活码;</p>
        <p>c)需记住登录第三方账户的日期，通过该登录日期，第三方在激活密钥的基础上安装了数字内容;</p>
        <p>d)已验证数字内容的完整功能所必需的技术要求，并确认设备满足这些要求。</p>
        <p>睿云联不承担因不合适的互联网连接、未充分满足技术要求或激活码或登录数据丢失而造成的性能缺陷索赔。</p>
        <p>6.2. 客户授权许可:</p>
        <p>a)将数字内容安装在属于客户端或最终用户的一台设备上</p>
        <p>b)使用所提供的激活密钥激活一个用户帐户，以便该帐户能够安装数字内容;</p>
        <p>c)安装许可仅限于客户明确承认的特定区域。数码内容只能安装在许可证有效的区域内。数字内容本身的功能仅限于特定区域。许可证的范围根据所选择服务的类型而有所不同。客户承诺在订购特定服务前，先熟悉其许可的内容。</p>
        <p>客户需承认，版权法适用于所提供服务中的数字内容。客户不可通过授权许可证中标明以外的方式使用服务。</p>
        <p><strong>7. 个人资料</strong></p>
        <p>7.1. 客户同意以下个人资料的处理: 姓名、姓氏、居住地址、登记号码、电子邮件地址、电话号码(以下统称“个人资料”)。</p>
        <p>7.2.
            客户同意睿云联可以以执行合同产生的权利和义务为目的而管理用户帐户，处理其个人数据。除非客户另有选择，否则睿云联同意，睿云联也可以处理其个人数据，以便向客户发送信息和商业通信。订立合约并不以同意根据本条在整个范围内处理个人资料为条件。
        </p>
        <p>7.3. 客户需在注册账号及下单时提供正确且真实的个人数据，并需及时将信息变动通知给睿云联。</p>
        <p>7.4. 睿云联可授权第三方作为处理方处理客户的个人数据。未经客户事先同意，睿云联不会向第三方披露个人数据。</p>
        <p>7.5. 个人资料的处理期限不受限制。个人资料会以自动电子形式处理，或以非自动印刷形式处理。</p>
        <p>7.6. 客户需确认所提供的个人资料是准确的，并已被告知需自愿提供个人资料。</p>
        <p>7.7. 客户如果要求提供处理其个人数据的信息，睿云联必须提供该信息。睿云联有权要求支付适当的费用，不超过根据上文提供信息所必需的费用。</p>
        <p><strong>8. 责任</strong></p>
        <p>8.1. 睿云联不承担由外部环境造成而非睿云联或睿云联工作人员造成的损害赔偿,特别是机械损伤,未经授权的安装,未经授权人士介入服务、互联网连接故障、过压或其他类似事件。</p>
        <p>8.2. 睿云联只对已经证实造成其违反义务而给客户造成损失的实际损害负责;
            但是，客户如果是企业家，睿云联对客户提出的所有索赔的总责任以最高金额为限，该最高金额不得超过根据合同支付给睿云联的价款，该价款是该责任产生的基础。睿云联不对间接损害、利润损失和随后的损害负责，无论这些损害是什么，包括商业活动利润损失、营业额损失、商业活动中断、信誉损失、声誉损害、商业活动信息的丢失或任何其他财务损失或损害。
        </p>
        <p>8.3. 除上述规定的损害赔偿要求外，客户(如果是企业家)明确放弃因睿云联违反义务而产生的损害赔偿的权利。在此条款的基础上，客户明确同意并声明其不认为该条款是不合理的期望。</p>
        <p><strong>9. 知识产权</strong></p>
        <p>睿云联拥有并保留适用于睿云联提供的服务的任何和所有专利、商标、工业或实用新型、版权、商业秘密和其他知识产权和工业产权的所有权利、法律主张和股份。这并不影响客户权利和睿云联义务。提供服务并不包括睿云联拥有的商标、公司名称、专利、工业或实用新型或已知知识的使用许可，客户不得使用睿云联拥有的以及睿云联提供的服务相关的任何知识产权或工业产权对应的权利。除非睿云联书面同意与睿云联拥有的知识产权或工业产权相对应的权利(在给定的使用同意中明确定义的情况下)。
            如果该等同意已被批准，或与睿云联拥有的知识产权或工业产权相对应的权利使用协议已达成，则该使用权不能适用于该等同意或协议中未明确规定的任何其他情况。如果同意不是以书面形式给予的，或者协议不是以书面形式达成的，则不可能推断或适用口头同意或达成协议。只有书面形式才是有效和有约束力的。如果未授权使用睿云联拥有的知识产权或工业产权对应的任何权利，客户应在睿云联通知后立即停止该等未授权使用，并赔偿所有损害，放弃所有不合理的收入，并向睿云联支付适当赔偿。
            睿云联也有权要求法院在任何时候发布一项禁止此类非法使用的初步措施。在侵犯睿云联拥有的知识产权或工业产权的情况下，睿云联可能不采取行动不能被解释为同意或接受该等使用、放弃或限制睿云联的权利。</p>
        <p><strong>10. 保密信息</strong></p>
        <p>如果睿云联或客户彼此提供的信息(技术/业务信息)被确定为机密信息或可能被视为机密信息，此信息的使用不得与提供信息的目的相冲突，也不得在未经另一方事先书面(包括电子邮件)同意的情况下提供给第三方。</p>
        <p><strong>11. 最终条款</strong></p>
        <p>11.1. 客户需同意遵守适用于该等服务的当地法律法规。</p>
        <p>11.2. 本合同及其条款由睿云联以电子形式存档，不可访问。</p>
        <p>11.3. 睿云联公司联系方式:睿云联(厦门)网络有限公司，电子邮箱: sales@AKUVOX.COM，电话+ (852)3620-3460</p>
        <p>11.5. 未经睿云联事先书面同意，客户无权将其在本合同中产生的任何权利或义务转让给第三方。睿云联有权将其在合同中产生的任何权利和义务转让给第三方。</p>
    </div>
</template>

<style lang="scss" scoped>
p {
    margin-top: 10px;
}
.policy-ul {
    left: 20px;
    position: relative;
    width: 90%;
    li {
        list-style: disc;
    }
}
</style>
